<template>
	<div class="container">
		<LoginWrap v-show="showLogin" v-on:close-login="closeLogin()" />
		<div class="news-list">
			<van-list v-model="loading" @load="getPushMessageList" :finished="finished" finished-text="没有更多了"
				:error.sync="error" error-text="请求失败，点击重新加载">
				<div v-for="(item,index) in  list" v-bind:key="index" class=" flex-row align-center item"
					@click.stop="navDetail(index)">
					<div class="item-img-sec">
						<img :src="item.pic" class="item-img" />
					</div>
					<div class="flex-column justify-center">
						<div class="item-title">{{item.title}}</div>
						<div class="item-sen-title">{{item.subTitle}}</div>
					</div>
				</div>
			</van-list>
		</div>
		<div class="bot-tab-sec flex-row align-center">
			<div class="bot-tab-item flex-column align-center  justify-center" @click.stop="navHome()">
				<img src="../../assets/home.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name">C2C</div>
			</div>
			<div class="bot-tab-item flex-column align-center bot-tab-item-active " >
				<img src="../../assets/tab/msg-sel2.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name">公告</div>
			</div>
			<div class="bot-tab-item flex-column align-center justify-center" @click.stop="navCollect()">
				<img src="../../assets/collect_tab.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name">我的藏品</div>
			</div>
			<div class="bot-tab-item flex-column align-center justify-center" @click.stop="navMember()">
				<img src="../../assets/mine.png" class="bot-tab-item-icon" />
				<div class="bot-tab-item-name">我的</div>
			</div>
		</div>
		<div class="icp flex-row align-center space-around">
			<div @click.stop="navBa()">沪ICP备17003041号-4</div>
			<div class="flex-row align-center">
				<!-- <img src="../assets/beiab.png" class="beiab-img"> -->
				<div @click.stop="navBa2()">沪公网安备 31011402009626号</div>
			</div>
		</div>
	</div>
</template>

<script>
	import util from '../../config/util.js';
	import config from '../../config/config.js';
	import LoginWrap from '@/components/Login.vue';
	import {
		Toast,
		// Sticky,
		List,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css'
	import 'vant/lib/sticky/index.css'
	import 'vant/lib/list/index.css';
	import 'vant/lib/dialog/index.css';
	import Vue from 'vue';
	Vue.use(List);
	export default {
		data() {
			return {
				pageNum: 1,
				list: [],
				finished:false,
				loading: false,
				showLogin: false,
				error:false,
			}
		},
		components: {
			LoginWrap,
		},
		methods: {
			navBa2() {
				window.location.href = "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011402009626"
			},
			navBa() {
				window.location.href = "https://beian.miit.gov.cn/#/Integrated/index"
			},
			navHome() {
				this.$router.replace('/')
			},
			navCollect() {
				if (!this.$store.state.user.token) {
					this.login()
				} else {
					this.$router.replace('/collect')
				}
			},
			navMember() {
				if (!this.$store.state.user.token) {
					this.login()
				} else {
					this.$router.replace('/member')
				}
			},
			login() {
				this.showLogin = true;
				this.$forceUpdate()
			},
			closeLogin() {
				this.showLogin = false;
				// this.list = [];
				// this.more = true;
				// this.pageNum = 1;
				// this.finished = false;
				// this.getGoods();
				// this.$forceUpdate()
			},
			closeLogin2() {
				console.log(1)
			},
			navDetail(index) {
				let id = this.list[index].id;
				this.$router.push('/newsDetail?id=' + id)
			},
			getPushMessageList() {
				const that = this
				let params = new FormData()
				params.append('pageNum', that.pageNum);
				params.append('pageSize', 20);
				that.axios.post(config.requestUrl + '/search/getPushMessageList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let newList = response.data.obj.list;
						let list = that.list;
						let finished = false;
						if (response.data.obj.isLastPage || !response.data.obj.hasNextPage) finished =
							true;
						list = list.concat(newList);
						that.list = list;
						that.finished = finished;
						that.error = false;
					} else {
						let msg = response.data.msg;
						if (msg == '') msg = '获取失败，请稍后重试'
						Toast(msg);
						that.error = true;
					}
					that.loading = false;
				}, response => {
					that.loading = false;
					that.error = true;
					Toast('获取失败，请稍后重试');
				})
			},

		},

	}
</script>

<style scoped="scoped">
	page {
		background: #F4F4F4;
	}

	.container {
		background: #F4F4F4;
		min-height: 100%;
	}

	.news-list {
		padding: 20px 20px 150px 20px;
	}

	.item {
		width: 710px;
		height: 120px;
		background: #FFFFFF;
		border-radius: 10px;
		margin-top: 20px;
		padding: 0 27px;
		box-sizing: border-box;
	}

	.item-img-sec {
		position: relative;
		margin-right: 37px;
	}

	.item-dot {
		position: absolute;
		z-index: 10;
		top: -3px;
		right: -3px;
		width: 16px;
		height: 16px;
		background: #FF0000;
		border-radius: 50%;
	}

	.item-img {
		width: 86px;
		height: 86px;
		border-radius: 10px;
	}

	.item-title {
		font-size: 28px;
		font-weight: bold;
		color: #000000;
		line-height: 30px;
	}

	.item-sen-title {
		font-size: 24px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 24px;
		margin-top: 10px;
	}
</style>